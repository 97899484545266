"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractGuestExternalAttendeeId = exports.extractGuestExternalId = void 0;
/**
 * Generate an `id` value for `GuestExternal` based on the details of the
 * record.
 */
function extractGuestExternalId(source) {
    return `${source.serviceType}:${source.externalId}`;
}
exports.extractGuestExternalId = extractGuestExternalId;
/**
 * Generate an `Attendee.id` from a `GuestExternal` record. `GuestExternal`
 * contains sufficient detail to create a synthetic `Attendee` record and in
 * that case the generated `id` for the `Attendee` should be stable.
 */
function extractGuestExternalAttendeeId(source) {
    return `${source.serviceType}:${source.externalId}:attendee`;
}
exports.extractGuestExternalAttendeeId = extractGuestExternalAttendeeId;
