"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoSource = exports.ProgressiveHls = exports.Progressive = void 0;
const typebox_1 = require("@sinclair/typebox");
const VideoSourceBase = typebox_1.Type.Object({
    drmEnabled: typebox_1.Type.Optional(typebox_1.Type.Boolean({
        title: 'Is DRM protected',
        description: 'If true, the video will be protected by a DRM license. Digital Rights Management (DRM) is a digital license system that allows content creators and distributors to control who can use their content, and how they can use it. ',
        default: false,
    })),
    serversideForensic: typebox_1.Type.Optional(typebox_1.Type.Boolean({
        title: 'Has Serverside Forensic',
        description: 'If checked, the video is protected by Serverside Forensic Watermarking.',
        default: false,
    })),
    assetId: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Asset Id',
        description: 'Asset Id from the asset manager',
    })),
    thumbnail: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Thumbnail',
        description: 'Thumbnail for the video',
    })),
});
const Hls = typebox_1.Type.Object({
    hls: typebox_1.Type.String({ title: 'HLS' }),
    ...VideoSourceBase.properties,
}, { $id: 'HLS' });
const Dash = typebox_1.Type.Object({
    dash: typebox_1.Type.String({ title: 'DASH' }),
    ...VideoSourceBase.properties,
}, { $id: 'DASH' });
const HlsDash = typebox_1.Type.Object({
    hls: typebox_1.Type.String({ title: 'HLS' }),
    dash: typebox_1.Type.String({ title: 'DASH' }),
    ...VideoSourceBase.properties,
}, { $id: 'HLSDASH' });
exports.Progressive = typebox_1.Type.Object({
    progressive: typebox_1.Type.String({ title: 'Progressive' }),
    ...typebox_1.Type.Pick(VideoSourceBase, ['assetId', 'thumbnail']).properties,
}, { $id: 'Progressive' });
exports.ProgressiveHls = typebox_1.Type.Object({
    progressive: typebox_1.Type.String({ title: 'Progressive' }),
    hls: typebox_1.Type.String({ title: 'HLS' }),
    ...typebox_1.Type.Pick(VideoSourceBase, ['assetId', 'thumbnail']).properties,
}, { $id: 'ProgressiveHLS' });
exports.VideoSource = typebox_1.Type.Union([
    Dash,
    Hls,
    HlsDash,
    exports.Progressive,
    exports.ProgressiveHls,
]);
