import {LazyQueryResultTuple, useLazyQuery} from '@apollo/client';
import {
  QuerySiteDetailsByDomain,
  type SiteDetailsByDomainQuery as SiteForPageListing,
  type SiteDetailsByDomainQueryVariables as SiteForPageListingVariables,
} from '@backstage/attendee-ui-types';
import {
  type PageData,
  type StateSetter,
  buildUpModules,
} from '@backstage/ui-render';
import {isPreviewDomain} from '@backstage/utils/domain-helpers';
import {
  useManagedRef as useRef,
  useSiteVersionId,
} from '@backstage-components/base';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {evictSiteByDomain, useOnGuestAuthChange} from '../../../apollo';
import {config} from '../../../config';
import {useShowId} from '../useShowId';

type Site = SiteForPageListing['site'];

export type SetSiteData = StateSetter<SiteForPageListing | undefined | null>;

interface PageListingResult
  extends Pick<
    LazyQueryResultTuple<SiteForPageListing, SiteForPageListingVariables>[1],
    'called' | 'error'
  > {
  data: SiteResult | undefined | null;
  fetchSite: () => void;
  setData: SetSiteData;
}

export interface SiteResult extends Omit<SiteForPageListing, 'site'> {
  site: Omit<Site, 'items'> & {items: PageData[]};
  showId?: string;
  domainName?: string;
}

export const usePageListings = (
  options: UsePageListingOptions
): PageListingResult => {
  const {domainName} = options;
  const locationRef = useRef(
    typeof window === 'undefined' ? undefined : window.location
  );
  // store `setIsLoading` in a Ref so the refetch hook doesn't need to depend on
  // `setIsLoading`
  const setIsLoadingRef = useRef(options.setIsLoading);
  // `data` is set from the `Promise` returned by calling query via apollo
  const [data, setData] = useState<SiteForPageListing | undefined | null>(
    undefined
  );
  const showId = useShowId(data?.site?.shows ?? [], options.pathShowId);
  const siteVersionId = useSiteVersionId() ?? null;

  // fetch page data, extract showId and add to context for the Attendee Container
  const [getSitePageListings, {called, error, loading}] = useLazyQuery<
    SiteForPageListing,
    SiteForPageListingVariables
  >(QuerySiteDetailsByDomain, {
    fetchPolicy: 'no-cache',
    context: {environmentId: showId},
  });
  // Clear query results when `sessionToken` changes
  const {hasSessionTokenChanged, updateSessionToken} =
    useOnGuestAuthChange(showId);

  // This is its own call so it can be used as a reloader or the initial load
  // function
  const fetchSite = useCallback(async () => {
    if (typeof domainName === 'undefined') {
      return;
    }
    try {
      if (hasSessionTokenChanged()) {
        evictSiteByDomain(domainName, siteVersionId);
        updateSessionToken();
      }
      const result = await getSitePageListings({
        variables: {
          domainName,
          showType: isPreviewDomain(config, locationRef.current)
            ? 'draft'
            : null,
          versionId: siteVersionId,
        },
        context: {environmentId: showId},
      });
      setData((current) =>
        JSON.stringify(current) === JSON.stringify(result.data)
          ? current
          : result.data
      );
    } catch (reason) {
      console.error(reason);
    }
  }, [
    domainName,
    getSitePageListings,
    hasSessionTokenChanged,
    showId,
    siteVersionId,
    updateSessionToken,
  ]);
  // Update "loading" state when `called` state changes or `data` changes
  useEffect(() => {
    const setIsLoading = setIsLoadingRef.current;
    const isLoading = !called || typeof data === 'undefined' || loading;
    setIsLoading(isLoading);
  }, [called, data, loading]);

  /*
   * Fetch initial data when `domainName` or `showId` changes. `showId` changing
   * will impact the authorization data sent with the request. These dependencies
   * are all in the useCallback, so they trigger this effect.
   */
  useEffect(() => {
    // Perform initial fetch
    fetchSite();
    // Set up listener for 'GuestAuth:success' event to refetch pages on session
    // token change
    const refetchPages = (): void => {
      const setIsLoading = setIsLoadingRef.current;
      setIsLoading(true);
      // Need to perform the fetch in the next tick so state updates related to
      // 'GuestAuth:success' have a chance to occur
      setTimeout(() => fetchSite(), 1);
    };
    document.body.addEventListener('GuestAuth:success', refetchPages);
    return () => {
      document.body.removeEventListener('GuestAuth:success', refetchPages);
    };
  }, [fetchSite]);

  // Only rebuild site & page data when `data`, `domainName` or `showId` changes
  const resultData = useMemo(() => {
    // no data yet or error fetching data
    if (typeof data === 'undefined' || data === null) {
      return data;
    }

    const siteVars = data.site.variables;
    const collections = data.site.collections;

    const pageData = data.site.pages.map(
      (page): PageData =>
        buildUpModules({
          config,
          page,
          showId,
          domainName,
          siteVars,
          collections,
        })
    );

    const result: SiteResult = {
      site: {
        ...data.site,
        items: pageData,
      },
      showId,
      domainName,
    };
    return result;
  }, [data, showId, domainName]);

  return {data: resultData, called, error, fetchSite, setData};
};

interface UsePageListingOptions {
  /** DomainName of Site data to be retrieved */
  domainName?: string;
  /** Function called to indicate when the application is in a loading state */
  setIsLoading: StateSetter<boolean>;
  /** Show id candidate parsed out from the pathname of the `URL` */
  pathShowId?: string;
}
