import {addDays} from 'date-fns';
import {css, Global} from '@emotion/react';
import {
  type ProcessedPageRoute,
  type StateSetter,
  UiRouter,
} from '@backstage/ui-render';
import {
  type AppPage,
  FALLBACK_PAGE_PATH,
  getFlowStorageKey,
  storage,
} from '@backstage-components/base';
import {type OverallFlowData} from '@backstage/flows';
import {FC, useEffect, useMemo, useReducer, useLayoutEffect} from 'react';
import {config} from './config';
import {AppContext} from './AppContext';
import {PageRoute} from './PageRoute';
import {PageRouteForEditor} from './PageRouteForEditor';

import {ContactLcdFallback} from './components/ContactLcdFallback';
import {FourOhFourLcdFallback} from './components/FourOhFourLcdFallback';
import {
  usePageListings,
  useDomainName,
  useRouterPrefixes,
  useShowId,
} from './hooks';
import {createSiteVariableLookup} from '@backstage/attendee-ui-types';

/**
 * Removes obsolete flows from local storage. This will affect only content
 * authors. Without this function, flows will accumulate in local storage and
 * eventually run out of memory.
 */
const clearOldFlows = (): void => {
  const now = Date.now();
  const keys = Object.keys(storage).filter((key) => key.startsWith('flows/'));
  if (keys.length < 3) {
    // Don't bother clearing if there are only a few flows. There are unlikely
    // to be multiple flows for any deployed sites.
    return;
  }
  keys.forEach((key) => {
    const value = storage.getItem(key);
    if (value) {
      try {
        const parsed: OverallFlowData = JSON.parse(value);
        const expiresAt = new Date(parsed.expiresAt ?? 1);
        if (expiresAt.getTime() < now) {
          storage.removeItem(key);
        }
      } catch (reason) {
        console.warn(`Could not delete localStorage value for ${key}`, reason);
      }
    }
  });
};

interface AppProps {
  container: HTMLElement;
  isLoading: boolean;
  setIsLoading: StateSetter<boolean>;
  isEditMode: boolean;
  setIsEditMode: StateSetter<boolean>;
}

export const App: FC<AppProps> = ({
  container,
  isLoading,
  setIsLoading,
  isEditMode,
  setIsEditMode,
}) => {
  const {domainName, isPreview, showId: pathShowId} = useDomainName() ?? {};
  const {data, fetchSite, setData} = usePageListings({
    domainName,
    setIsLoading,
    pathShowId,
  });

  const [isIframed, setIsIframed] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (window.parent !== window) {
      setIsIframed();
    }
  }, []);

  const site = data?.site;
  const flow = site?.flows[0];
  // Get show id
  const showId = useShowId(site?.shows ?? [], pathShowId);

  const siteCss = useMemo(
    () => css`
      ${site?.cssVariables ?? ''}
      ${site?.css ?? ''}
    `,
    [site?.css, site?.cssVariables]
  );

  const siteVariableLookup = useMemo(
    () => createSiteVariableLookup(site?.variables ?? []),
    [site?.variables]
  );

  const {
    router: prefix,
    withShowId: prefixWithShowId,
    withoutShowId: prefixWithoutShowId,
  } = useRouterPrefixes({
    domainName,
    isPreview,
    pathShowId,
    showId,
  });
  const domainRecord = site?.domains.find((d) => d.name === domainName);

  useEffect(() => {
    if (flow) {
      const newValue = Object.assign(
        {id: flow.id, expiresAt: addDays(new Date(), 2).toISOString()},
        flow.data
      );
      storage.setItem(
        getFlowStorageKey(domainName),
        JSON.stringify(newValue, null, 2)
      );
    }
  }, [domainName, flow]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearOldFlows);
  }, []);

  const pages = useMemo(() => site?.items ?? [], [site]);
  const {FallbackComponent, pageRoutes} = useMemo(
    () =>
      pages.reduce<ProcessedPages>(
        ({FallbackComponent, pageRoutes}, item) => {
          const component = isIframed ? (
            <PageRouteForEditor
              showId={showId}
              pageFields={item}
              page={item}
              getUpdatedSite={fetchSite}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              setData={setData}
            />
          ) : (
            <PageRoute showId={showId} page={item} />
          );
          if (item.pathname === FALLBACK_PAGE_PATH) {
            return {FallbackComponent: component, pageRoutes};
          } else {
            const pathWithoutShow = `${prefixWithoutShowId}${item.pathname}`;
            const pathWithShow = `${prefixWithShowId}${item.pathname}`;
            return {
              FallbackComponent: FallbackComponent,
              pageRoutes: pageRoutes.concat(
                {pathname: pathWithoutShow, component},
                {pathname: pathWithShow, component}
              ),
            };
          }
        },
        {FallbackComponent: <FourOhFourLcdFallback />, pageRoutes: []}
      ),
    [
      fetchSite,
      isEditMode,
      isIframed,
      pages,
      prefixWithShowId,
      prefixWithoutShowId,
      setData,
      setIsEditMode,
      showId,
    ]
  );
  const appPages = useMemo<AppPage[]>(
    () =>
      pages.map((item) => ({
        pathname: item.pathname,
        structure: item.structure,
      })),
    [pages]
  );

  if (typeof domainName === 'undefined' || domainName === '') {
    // No domain found
    return (
      <AppContext
        appPages={[]}
        domainName={domainName}
        showControllerType={site?.showControllerType ?? 'POLL'}
        showId={undefined}
        siteVariableLookup={siteVariableLookup}
      >
        <ContactLcdFallback />
      </AppContext>
    );
  } else if (typeof site === 'undefined' && config.stage === 'developer') {
    // Site not found
    return (
      <AppContext
        appPages={[]}
        domainName={domainName}
        showControllerType="POLL"
        showId={undefined}
        siteVariableLookup={siteVariableLookup}
      >
        <FourOhFourLcdFallback
          message={`Site Not Found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else if (
    typeof domainRecord === 'undefined' &&
    (config.stage === 'developer' || config.stage === 'local')
  ) {
    // No pages on domain
    return (
      <AppContext
        appPages={[]}
        domainName={domainName}
        showControllerType={site?.showControllerType ?? 'POLL'}
        showId={undefined}
        siteVariableLookup={siteVariableLookup}
      >
        <FourOhFourLcdFallback
          message={`No Pages found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else {
    return (
      <AppContext
        appPages={appPages}
        domainName={domainName}
        rootElement={container}
        showControllerType={site?.showControllerType ?? 'POLL'}
        showId={showId}
        siteVariableLookup={siteVariableLookup}
      >
        <Global styles={siteCss} />
        <UiRouter
          fallbackElement={FallbackComponent}
          isLoading={isLoading}
          pageRoutes={pageRoutes}
          prefix={prefix}
        />
      </AppContext>
    );
  }
};

interface ProcessedPages {
  /** Component to use as the fallback route */
  FallbackComponent: JSX.Element;
  /** Data to be rendered as a `Route` */
  pageRoutes: ProcessedPageRoute[];
}
