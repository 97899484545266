import {createRoot} from 'react-dom/client';
import React, {FC, useState} from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {ApolloProvider} from '@apollo/client';
import {client} from './apollo';
import {App} from './App';
import {PageLoader} from './components/PageLoader';
import {useAppHeight} from './hooks';
import './index.css';
// For `@backstage-components/video`
import '@lcdigital/lst-player-ui/dist/css/bitmovinplayer-ui.min.css';
// For `@backstage-components/chat`
import 'stream-chat-react/dist/css/index.css';

const Main: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  useAppHeight();

  return (
    <>
      {!isEditMode && <PageLoader isLoading={isLoading} />}
      <App
        // If `Main` is rendering then `container` must exist
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        container={container!}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    </>
  );
};

const container = document.getElementById('root');
// There's no fallback if the `#app` element doesn't exist; failure is
// appropriate
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <ChakraProvider>
        <Main />
      </ChakraProvider>
    </React.StrictMode>
  </ApolloProvider>
);
// Override the `addEventListener` on `document` so if `DOMContentLoaded` fires
// after the document is ready the associated callback is immediately invoked
(function (): void {
  const original = document.addEventListener.bind(document);
  type DOMContentLoadedListener = (
    this: Document,
    ev: DocumentEventMap['DOMContentLoaded']
  ) => unknown;
  function addEventListener<K extends keyof DocumentEventMap>(
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions
  ): void {
    if (type === 'DOMContentLoaded' && document.readyState !== 'loading') {
      // Assert `listener` is `DOMContentLoadedListener` which it must be
      // because the `type` has been checked that it is `DOMContentLoaded`
      (listener as DOMContentLoadedListener).call(
        document,
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        })
      );
    } else {
      original(type, listener, options);
    }
  }
  document.addEventListener = addEventListener;
})();
