"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentInstanceJson = exports.ContentInstanceModel = exports.ContentInstanceStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const slug_1 = require("../../data/slug");
const model_helpers_1 = require("../model-helpers");
exports.ContentInstanceStatus = typebox_1.Type.Union([
    typebox_1.Type.Literal('draft'),
    typebox_1.Type.Literal('published'),
]);
/**
 * Defines the JSON serialized shape of the data records.
 */
exports.ContentInstanceModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    slug: typebox_1.Type.String({ pattern: slug_1.slugPattern }),
    contentModelId: typebox_1.Type.String({ format: 'uuid' }),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    status: exports.ContentInstanceStatus,
    value: (0, model_helpers_1.JsonValue)(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ContentInstanceModel' });
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * the data records.
 */
exports.ContentInstanceJson = exports.ContentInstanceModel;
