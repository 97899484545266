"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldsToSchema = void 0;
const string_helpers_1 = require("@backstage/utils/string-helpers");
const typebox_1 = require("@sinclair/typebox");
const content_field_model_1 = require("../../model/content-field/content-field-model");
/** Creates a `Map` of Content Field schemas keyed by `kind` */
const createFieldKindLookup = () => {
    return content_field_model_1.ContentFieldKindDefs.anyOf.reduce((acc, kind) => {
        acc.set(kind.properties.kind.const, kind);
        return acc;
    }, new Map());
};
/** Converts a list of content fields into a JSON schema. */
const fieldsToSchema = (fields, options = {}) => {
    const fieldKindLookup = createFieldKindLookup();
    const properties = fields.reduce((acc, field) => {
        /** The base definition of this field kind */
        const definition = fieldKindLookup.get(field.kind);
        if (definition?.properties.value) {
            // `$id` comes from the SiteVariable definition, so its use here would be incorrect.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { $id, ...vals } = definition.properties.value;
            const def = {
                ...vals,
                // Overwrite any properties that are specific to this instance of the field.
                title: field.name,
                description: field.description,
            };
            // When in draft mode, incomplete data is ok, so all fields are optional.
            // String formatting should not be relaxed, though.
            acc[field.id] = options.isDraft ? typebox_1.Type.Optional(def) : def;
        }
        else {
            // To `throw` here may brick the UI. Instructional text for RJSF will be more helpful.
            acc[field.id] = typebox_1.Type.Literal(`Unknown Field Type: "${field.kind}"`);
        }
        return acc;
    }, {});
    const hash = (0, string_helpers_1.hashCode)(fields.map((f) => f.id).join(''));
    return typebox_1.Type.Object(properties, { $id: hash });
};
exports.fieldsToSchema = fieldsToSchema;
